<template>
  <div>
    <pp-card-body>
      <pp-tab-container>
        <pp-tab
          :tag="market.statusIs('active') ? 'router-link' : 'button'"
          :to="{ name: 'Mint', params: $route.params }"
          :active="$route.matched.map((route) => route.name).includes('Mint')"
          data-test="slider-mint"
          :disabled="market.statusIs('inactive')"
        >
          Mint
        </pp-tab>
        <pp-tab
          tag="router-link"
          :to="{ name: 'Redeem', params: $route.params }"
          :active="$route.matched.map((route) => route.name).includes('Redeem')"
          data-test="slider-redeem"
        >
          Redeem
        </pp-tab>
      </pp-tab-container>
    </pp-card-body>

    <router-view></router-view>
  </div>
</template>

<script>
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'

  export default {
    computed: {
      market() {
        const { tokenAddress1, tokenAddress2 } = this.$route.params
        return PendleAmmMarket.query().findBy('pair', (val) =>
          [tokenAddress1, tokenAddress2].every((tokenAddr) => val.includes(tokenAddr))
        )
      },
    },
    created() {
      if (this.market.statusIs('inactive')) {
        this.$router.push({ name: 'Redeem' })
      }
    },
  }
</script>
